import React, { Component } from "react"
import { Link } from "gatsby"
import SearchLights from "../components/Globals/SearchLights/SearchLights.js"

import Modal from "../components/Globals/Modal/Modal.js"
import work_screenshot_1 from "../images/full-stack-project-42-1.png"
import work_screenshot_2 from "../images/full-stack-project-42-2.png"
import full_stack_project_blockchain_1 from "../images/full-stack-project-blockchain-1.png"
import full_stack_project_blockchain_2 from "../images/full-stack-project-blockchain-2.png"
import full_stack_project_covid_1 from "../images/full-stack-project-covid-1.png"
import full_stack_project_covid_2 from "../images/full-stack-project-covid-2.png"
import OutsideClickHandler from "react-outside-click-handler"

import SEO from "../components/seo"
import desk from "../images/developer-london-skills-night-desk.svg"

export default class Work extends Component {
  state = {
    modal: false,
    modalId: "",
  }

  openModal = modalId => {
    return e => {
      const { modal } = this.state
      if (!modal) {
        this.setState({ modal: true, modalId })
      }
    }
  }

  closeModal = () => {
    const { modal } = this.state
    if (modal) {
      this.setState({ modal: false })
    }
  }

  render = () => {
    return (
      <div className="background_work">
        <SearchLights />
        <SEO title="Skills" />
        <div className="work_container_1">
          <div className="work_title">
            <h1>Recent projects</h1>
            <p>Here are some full stack projects that I've been working on.</p>
          </div>
        </div>

        <div className="work_container_2">
          {/* <SearchLights /> */}

          <div className="work_boxes_container">
            <div className="work_boxes">
              <img
                src={work_screenshot_2}
                className="unopened_image"
                onClick={this.openModal("project_1")}
              />
            </div>

            <div className="work_boxes">
              <img
                className="unopened_image"
                src={full_stack_project_blockchain_1}
                onClick={this.openModal("project_2")}
              />
            </div>
            <div className="work_boxes">
              <img
                src={full_stack_project_covid_1}
                className="unopened_image"
                onClick={this.openModal("project_3")}
              />
            </div>
          </div>
        </div>

        <div className="modal_container">
          <OutsideClickHandler onOutsideClick={this.closeModal}>
            <Modal
              onClick={this.closeModal}
              status={this.state.modal && this.state.modalId === "project_1"}
              title="INVENTORY MANAGEMENT"
              technologies="HTML • CSS3 • Bootstrap • Django • PostgreSQL • Heroku"
              description="I built an inventory management web application for Cambridge based tech consulting company, 42 Technology. The system allows teams within the company to manage their project specific inventory. Simplicity of the database was priority so box content was recorded as a single string value. This method allows quick and easy changes to be implimented to a small, uncomplex dataset."
              image={work_screenshot_1}
              button_words="See Simplified Demo (free/ slow server!)"
              link="http://inventorymanagementapp.developwithtom.com/inventory_management_app/dashboard"
            />

            <Modal
              onClick={this.closeModal}
              status={this.state.modal && this.state.modalId === "project_2"}
              title="BLOCKCHAIN DATA ANALYTICS"
              technologies="React • Django REST • PostgreSQL • Heroku"
              description="Providing data analytics on blockchain networks. Im very interested in using insights to help blockchain developers increase robustness of their networks."
              image={full_stack_project_blockchain_2}
              button_words="Work in progress"
              // link="http://www.developwithtom.com/inventory_management_app/dashboard"
            />

            <Modal
              onClick={this.closeModal}
              status={this.state.modal && this.state.modalId === "project_3"}
              title="COVID-19 SYMPTOM CHECKER"
              technologies="HTML • CSS3 • Django • PostgreSQL • Digital Ocean"
              description="I built a Covid-19 symptom checker for a Ukrainian health organisation. The web app uses current WHO guidelines to help patients diagnose their symptoms and provides actionable advice. This was a voluntery project."
              image={full_stack_project_covid_2}
              button_words="See Simplified Demo"
              link="http://www.healthbooq.com"
            />
          </OutsideClickHandler>
        </div>
        <div className="work_desk" />
      </div>
    )
  }
}
